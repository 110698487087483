<template>
  <el-container class="container">
    <el-header class="c-header">
      <el-page-header @back="goBack" content="智能抠图"> </el-page-header>
    </el-header>
    <el-main style="padding: 3px; margin-top: 50px; margin-bottom: 50px; overflow-y: auto; height: calc(100% - 100px)">
      <el-row :gutter="1" style="height: 100px">
        <el-col :span="7" style="margin: 5px; background-color: #4a5569; border-radius: 8px; display: flex; align-items: center; justify-content: center; overflow: hidden">
          <el-image :src="thumbnail" :preview-src-list="preview_thumbnail" fit="contain" v-if="thumbnail"></el-image>
        </el-col>
        <el-col :span="6">
          <input type="file" ref="fileInput" style="display: none" accept="image/*" @change="handleFileChange" />
          <span style="display: block; position: absolute; margin-top: 20px; font-size: 12px; color: #5b5a5a">原图</span>
          <el-button type="primary" size="mini" style="position: absolute; transform: translate(0, 50px)" @click="openFileInput" round>{{ btnText }}</el-button>
        </el-col>
        <el-col :span="6">
          <div class="grid-content"></div>
        </el-col>
        <el-col :span="5"><div class="grid-content"></div></el-col>
      </el-row>
      <div class="c-body">
        <el-row style="height: 360px; display: flex; align-items: center; justify-content: center; background-color: #4a5569">
          <el-col :span="24">
            <el-image :src="currentPicUrl" :preview-src-list="pic_results" fit="contain" v-if="currentPicUrl"></el-image>
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="1" style="height: 50px; width: 100%"> </el-row>
    </el-main>
    <el-footer style="height: 50px; position: fixed; bottom: 0; width: 100%; z-index: 2000; background-color: #171631; border-top: 0.1mm solid #22374d">
      <el-button type="primary" style="position: relative; left: 70%; top: 50%; transform: translate(0, -50%)" @click="submit" round>抠图</el-button>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      loadingProgress: 60,
      loadingInterval: null,
      loading: null,
      btnText: '上传图片',
      thumbnail: '',
      preview_thumbnail: [],
      pictureId: '',
      pic_results: [],
      current_pic_index: 0,
      currentTaskId: null,
      intervalId: null
    };
  },
  methods: {
    goBack() {
      this.$router.push('/function');
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    checkFileSize(file) {
      console.log('文件：', file);
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning('图片大小超限，文件不能超过 5M');
        return false;
      }
      return true;
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      if (!file.type.includes('image')) {
        this.$message.warning('只能上传图片！');
        return;
      }

      if (!this.checkFileSize(file)) {
        return;
      }
      const formData = new FormData();
      formData.append('file', file);

      this.$http
        .post('file/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.data.code !== '0') {
            this.$message.error('上传失败！');
            return;
          }

          console.log('上传成功', response.data);
          this.pictureId = response.data.data;
          const reader = new FileReader();
          reader.onload = (event) => {
            this.thumbnail = event.target.result;
          };
          reader.readAsDataURL(file);

          this.preview_thumbnail = [];
          this.preview_thumbnail.push(this.convertFileIdToUrl(this.pictureId));

          // 定时任务关闭
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }

          this.btnText = '重新上传';
        })
        .catch((error) => {
          console.log(error.toString());
          this.$message.error('服务异常，请稍后再试！');
        });
    },

    submit() {
      if (!this.pictureId) {
        this.$message.warning('请先上传图片！');
        return;
      }

      // 定时任务关闭
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      // 清除pic_results
      this.pic_results = [];

      const params = {};
      params.fileId = this.pictureId;
      this.$http
        .post('cutout-params/submit', params, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          const { code, data, message } = response.data;
          if (code !== '0') {
            this.$message.error(message);
            return;
          }
          this.currentTaskId = data;
          this.$message.success('提交成功！');

          this.loadingProgress = 60;
          this.loading = this.$loading({
            lock: true,
            text: '图片生成中... ' + this.loadingProgress + '秒',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          this.loadingInterval = setInterval(() => {
            this.loadingProgress -= 1;
            this.loading.setText('图片生成中... ' + this.loadingProgress + '秒');
            if (this.loadingProgress === 0) {
              this.closeLoading();
            }
          }, 1000);

          // 启动定时拉取
          this.startFetchResult();
        })
        .catch((error) => {
          console.log(error.toString());
          this.closeLoading();
          this.$message.error('服务异常，请稍后再试！');
        });
    },
    closeLoading() {
      this.loading.close();
      if (this.loadingInterval) {
        clearInterval(this.loadingInterval);
      }
    },
    convertFileIdToUrl(fileId) {
      return this.$http.defaults.baseURL + '/file/download/' + fileId;
    },

    startFetchResult() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        const params = { taskId: this.currentTaskId };
        this.$http
          .get('fetch-result', { params })
          .then((response) => {
            if (response.data.code !== '0') {
              console.log('定时拉取结果失败：' + response.data.message);
              return;
            }

            console.log('定时拉取结果成功', JSON.stringify(response.data));
            if (response.data.data.fileIds.length > 0) {
              this.closeLoading();
              this.pic_results = response.data.data.fileIds.map((fileId) => {
                return this.convertFileIdToUrl(fileId);
              });

              if (this.intervalId) {
                clearInterval(this.intervalId);
              }
            }
          })
          .catch((error) => {
            console.log(error.toString());
            this.$message.error('服务异常，请稍后再试！');
          });
      }, 2000);
    }
  },
  mounted() {
    if (this.$isAndroidChrome()) {
      this.$refs.fileInput.removeAttribute('accept');
    }
  },
  computed: {
    currentPicUrl() {
      if (this.pic_results.length > 0) {
        return this.pic_results[this.current_pic_index];
      }
      return null;
      // return 'http://cybershoes.art/api/file/download/955844E0FDD17DAF6ECC29180D99024B.jpg';
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.loadingInterval) {
      clearInterval(this.loadingInterval);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 0;
  background-color: #171631;

  overflow: hidden;
  height: 100vh;
  .el-main {
    padding: 3px;
  }
}

.c-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  z-index: 2000;
  //border-bottom: 0.1mm solid #22374d;
  background-color: #171631;
  color: aliceblue;
}

.c-header {
  :deep(.el-page-header__content) {
    color: aliceblue;
  }
}

.c-body {
  //background-color: #171631;
  overflow: hidden;
  border-radius: 20px;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.el-row {
  display: flex;
  width: 100%;
  margin: 0;
}
</style>
